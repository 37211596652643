import Navbar from '../externals/Navbar';
import Hero from '../externals/Hero';
import Footer from '../externals/Footer';
import { Link } from 'react-router-dom';

function Home(){
    return(
        
        <div>  
            <Navbar />
             <Hero />
             
           
             <section id="ticket-ordering">
                <div className = "row">
                    
                    
                        <div className="container text-center">     
                            <Link to="/Events/National Championship" style={{textDecoration:'none', color:'black'}}>
                                <br></br>
                                <br></br>
                               
                            </Link>
                        </div>
               

                    <div className="col"></div>
                   
                </div>
            </section> 

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>   
             
            <div className="row">
                <hr></hr>
            </div>

            <br></br>
            
            <section id="Introduction">
                <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                        <h2>Home</h2>
                    <p className="text-left">Doamnelor și domnilor, trăgători și entuziaști, bine ați venit într-o destinație de neegalat în care converg precizia, îndemânarea și adrenalina - poligonul nostru de tragere de ultimă oră. Astăzi, pornim într-o călătorie pentru a explora un paradis conceput pentru cei care caută să-și perfecționeze trăsăturile, să se cufunde în lumea împușcăturii și să-și ridice abilitățile la noi culmi. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="weapons.jpeg" alt="weapons"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>
            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>


            <section id="home-secII">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>O poartă către precizie</h2>
                    <p className="text-left">Amplasat în aceste ziduri este un tărâm dedicat artei și științei împușcăturii. Poligonul nostru de tragere nu este doar un loc pentru a trage cartușele; este un spațiu în care tirul devine o formă de artă. Am creat un mediu care se adresează începătorilor dornici de a învăța, practicienilor calificați care își perfecționează meseria și profesioniștilor care își perfecționează tehnicile. Acesta este un loc în care fiecare împușcătură trasă este un pas către măiestrie. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="shooter.jpeg" alt="shooter"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>

            <section id="home-secIII">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>Dincolo de Bang</h2>
                    <p className="text-left">Dar raza noastră de acțiune nu se referă doar la sunetul focurilor de armă; este vorba despre promovarea disciplinei, controlului și concentrării. Este vorba despre simfonia respirației, alinierea vederii și apăsarea declanșatorului care creează o fotografie armonioasă. Este vorba despre depășirea granițelor, cucerirea provocărilor și îmbrățișarea creșterii personale. Aici vei descoperi o abordare holistică a tirului – una care se extinde dincolo de momentul aprinderii. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="3.jpeg" alt="shooters"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                

            <div className="row">
                <hr></hr>
            </div>

            <section id="home-secIV">   
            <div className="row">
                    <div className="col"><br></br><br></br></div>
                </div>
                <div className="row">
                    <div className="col"></div>
                    <div className="col-md-4 col-sm-6">
                    <h2>Siguranta pe primul loc</h2>
                    <p className="text-left">Înainte de a pătrunde mai adânc în inima poligonului nostru de tragere, permiteți-ne să subliniem angajamentul nostru neclintit față de siguranță. Siguranța nu este doar o prioritate; este înrădăcinată în fiecare aspect al operațiunilor noastre. Din momentul în care pășiți pe poligon, veți găsi o respectare meticuloasă a protocoalelor, îndrumarea ofițerilor de poligon cu experiență și un mediu securizat care vă permite să vă concentrați numai pe călătoria dvs. de tir. </p>
                    </div>
                    <div className="col-md-4 col-sm-8">
                            <div className="card shadow-lg">
                                <div className="card-body">
                                    <img className="img-fluid" src="range.jpeg" alt="reange"></img>
                                </div>
                            </div>
                    </div>
                    <div className="col">
                        
                    </div>
                </div>

            </section>

            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
                
        <Footer />
            
        </div>
        
    )
}

export default Home;