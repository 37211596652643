import SideNavbar from "../externals/SideNavbar";
import Competitors from "../externals/Competitors";
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

function Scoring(props){

    const authMap = {
        "Samir Ilie-Rusu" : "*",
        "Andrei Stefan" : "*",
        "Octavia Codru" : "*",
        "Cosmin Codru" : "*",
        "Dan Dimitrescu" : "*",
        "Edy Franke" : "etapa7",
        "Andrei Stoica" : "etapa1",
        "Sebastian Cosma" : "*",
        "Calin Baciu" : "etapa6",
        "Mihai Albu" : "etapa2"
    }

    var user = window.localStorage.getItem("uName");
    var isLogged = window.localStorage.getItem("logged");
    var isAuth = authMap[user];
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false)
    
    useEffect(()=>{
        setToggle(!toggle)
        if((isAuth !== "*") && (isAuth !== props.stage))
        {
            
            navigate("/Admin/Dashboard");
            alert("Nu aveti drepturi pentru a accesa aceasta etapa!");
            
            
        }
    }, [toggle])

    if(isLogged === "true"){
        
        if((isAuth === "*") || (isAuth === props.stage))
        {
            return(
                <div>
                    <div className="row">
                        <div className='col'>
                            <SideNavbar username={user}/>
                        </div>
                        <div className='col-6'>
                            <Competitors stage = {props.stage} trial = {props.trial} shots = {props.shots}/>
                        </div>
                    </div>
                </div>
            );
        } 
    }
    else
    {
        return(
            <>
                <p>You have to login to see this page!</p>
            </>
            );
    }

    /*function CompetitorsCallback()
    {
        return(
            <>
                <Competitors stage = {props.stage} trial = {props.trial} />
            </>
        );
    }*/

}

export default Scoring;