import SideNavbar from "../externals/SideNavbar";
import OverviewTable from "../externals/OverviewTable";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';


function Overview(props){

    const authMap = {
        "Samir Ilie-Rusu" : "*",
        "Andrei Stefan" : "*",
        "Octavia Codru" : "*",
        "Cosmin Codru" : "*",
        "Dan Dimitrescu" : "*",
        "Edy Franke" : "etapa7",
        "Andrei Stoica" : "etapa1",
        "Sebastian Cosma" : "*",
        "Calin Baciu" : "etapa6",
        "Mihai Albu" : "etapa2"
    }

    var user = window.localStorage.getItem("uName");
    var isLogged = window.localStorage.getItem("logged");
    var isAuth = authMap[user];
    const navigate = useNavigate();
    const [toggle, setToggle] = useState(false)
    
    useEffect(()=>{
        setToggle(!toggle)
        if((isAuth !== "*") && (isAuth !== props.stage))
        {
            
            navigate("/Admin/Dashboard");
            alert("Nu aveti drepturi pentru a accesa aceasta etapa!");
            
            
        }
    }, [toggle])

    if(isLogged === "true"){
        if((isAuth === "*") || (isAuth === props.stage)){
            return(
                <div>
                    <div className="row">
                        <div className='col'>
                            <SideNavbar username={user}/>
                        </div>
                        <div className='col-6'>
                            <OverviewTable stage = {props.stage} />
                        </div>
                    </div>
                </div>
            );
        }
    }
    else
    {
        return(
            <>
                <p>You have to login to see this page!</p>
            </>
            );
    }

}



export default Overview;